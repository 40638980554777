import { LOCALE_DEFAULT } from '@model/const/locales';

/* There's duplicate code here from core/mixins/translations.js
* But it has to be this way
* A) because trans function expects translations as well and
* B) because we do need it as a mixin for slices and here for slice settigns
*
* Please advice if that can be moved somewhere and work both places.
*/
function replaceNestedValues(obj, translations) {
    if (Array.isArray(obj)) {
        return obj.map((item) => {
            if (typeof item === 'object') {
                return replaceNestedValues(item, translations);
                // eslint-disable-next-line no-else-return
            } else if (translations[item] !== undefined) {
                return translations[item];
            } else {
                return item;
            }
        });
    }

    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
            acc[key] = replaceNestedValues(value, translations);
        } else if (translations[value] !== undefined) {
            acc[key] = translations[value];
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});
}

function trans(key, translations) {
    if (typeof key === 'object') return replaceNestedValues(key, translations);
    return translations[key] || key;
}

export default {
    namespaced: true,

    state: () => ({
        items: {},
    }),

    getters: {
        getLocales(state) {
            if (!state.items['s-locale-selector']) return [];
            return state.items['s-locale-selector'].columns.flatMap(
                (column) => column.regions.flatMap((region) => region.locales),
            );
        },
    },

    actions: {
        getSyncedData(store, args) {
            const { slice, locale } = args;
            const data = this.$context.Synced.slicesData.query(slice, locale, LOCALE_DEFAULT);

            const translations = data?.translations;
            if (!translations) return store.commit('setSyncedData', { slice, data });

            Object.keys(data).forEach((key) => {
                data[key] = trans(data[key], translations);
            });

            return store.commit('setSyncedData', { slice, data });
        },
    },

    mutations: {
        setSyncedData(state, { slice, data }) {
            state.items[slice] = data;
        },
    },
};
