import { mapActions, mapState } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    data() {
        return {
            isVisibleSURL: false,
        };
    },

    computed: {
        ...mapState({
            productItems: (state) => state.products.items,
            productID: (state) => state.pages.page.product_id,
        }),

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        language() {
            return this.locale.split('-').shift();
        },

        pageSettings() {
            return this.settings || this.page?.settings || {};
        },

        pageOptionsSURL() {
            return this.pageSettings?.optionsSURL || this.pageSettings?.page?.optionsSURL || {};
        },
    },

    mounted() {
        window.addEventListener('pageshow', this.pageShow);
    },

    beforeDestroy() {
        window.removeEventListener('pageshow', this.pageShow);
    },

    methods: {
        ...mapActions({
            generateSessionURL: 'cleverbridge/get',
        }),

        pageShow(event) {
            if (event && event.persisted) {
                // The page is loading from a cache
                const html = document.getElementsByTagName('html')[0];
                html.style.display = 'none';
                window.location.reload();
            }
        },

        storePURL(segment) {
            const purl = `purl-${segment}-standard-us`;
            const product = this.productItems?.[this.productID] || {};
            return product?.parameters?.common?.cleverbridge?.purl || purl;
        },

        storeOptionsSURL(settings) {
            const page = document.location.href.replace(/\?.*/, '');
            const { shopId, segment, targetPathname, params } = settings;

            const utm = {};
            const labels = ['utm_campaign', 'utm_medium', 'utm_source', 'x-audience', 'x-tenant_id', 'x-wave'];
            labels.forEach((name) => {
                const value = sessionStorage.getItem(name);
                if (value) utm[name] = value;
            });

            const options = {
                shopId: shopId || 882,
                targetPathname: targetPathname || this.storePURL(segment),
                params: {
                    cart: '',
                    language: this.locale === 'pt-br' ? 'pb' : this.language,
                    'x-page': page,
                    'x-segment': segment || '',
                    ...params,
                    ...utm,
                    ...this.pageOptionsSURL,
                },
            };

            Object.keys(options.params).forEach((k) => {
                options.params[k] = options.params[k].toString().replace('LOCALE', this.locale);
            });

            return options;
        },

        async redirectToCleverbridge(options) {
            try {
                this.isVisibleSURL = true;
                const { data } = await this.generateSessionURL(options);
                window.location.assign(data.data);
            } catch (e) {
                const url = `https://store.acronis.com/${options.shopId}/${options.targetPathname}`;
                const param = Object.entries(options.params)
                    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
                    .join('&');
                window.location.assign(`${url}?${param}`);
            }
        },
    },
};
