import { RawAxiosRequestConfig } from 'axios';
import getAPIService from '@api/service';

export default {
    namespaced: true,

    state: () => ({
    }),

    actions: {
        async createLeadRequest(store: never, data: Record<string, any>) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                method: 'POST',
                url: '/svc/v1/marketing/forms/leads',
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === 200,
                data,
            };

            const response = await client.request(cfg);
            return response;
        },
        async updateLeadRequest(store: never, data: Record<string, any>) {
            const client = getAPIService(this.$context);

            const cfg: RawAxiosRequestConfig = {
                method: 'PUT',
                url: `/svc/v1/marketing/forms/leads/${data.osauid}`,
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === 200,
                data,
            };

            const response = await client.request(cfg);
            return response;
        },
    },
};
