import { StatusCodes } from 'http-status-codes';
import getAPIService from '@api/service';

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        async sendChatEmail(store: never, data: Record<string, any>) {
            const client = getAPIService(this.$context);

            const cfg = {
                method: 'POST',
                url: '/svc/v1/acronis-contacts/sfchat/case',
                timeout: 60 * 1000,
                validateStatus: (status: number) => status === StatusCodes.OK,
                data,
            };

            const response = await client.request(cfg);
            return response.data;
        },
    },
};
