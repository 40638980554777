import CryptoJS from 'crypto-js';
import { range } from 'lodash';
import { LOCALE_DEFAULT } from '@model/const/locales';

export default {
    globalOverflowProperty(overflow) {
        if (process.env.VUE_ENV === 'server') return;

        const isValid = ['hidden', 'visible', 'auto', 'scroll'].includes(overflow);
        if (!isValid) return;

        document.documentElement.style.overflow = overflow;
    },

    handleHtmlInTranslations(source, replacements) {
        let result = source || '';
        replacements.forEach((item) => {
            const regex = RegExp(String.raw`${item.target}\('(.*?)'\)`, 'g');
            result = result.replace(regex, item.replacement);
        });
        return result;
    },

    buildImageLink(baseURL, template, imageId = '', extension = 'png') {
        return `${baseURL}/images/${template}/${imageId.replace('@', '')}.${extension}`;
    },

    isSafariBrowser() {
        const ua = window.navigator.userAgent;
        return ua.indexOf('Safari') > -1 && ua.indexOf('Chrome') === -1;
    },

    getArrayOfMonths(locale = LOCALE_DEFAULT, month = 'long') {
        const options = { month: month as Intl.DateTimeFormatOptions['month'] };
        return range(1, 13).map((m) => new Date(`2000/${m}/1`).toLocaleString(locale, options));
    },

    sleep(ms: number) {
        return new Promise((resolve) => { setTimeout(resolve, ms); });
    },

    slugify(input) {
        // https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1

        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        return input
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of a text
            .replace(/-+$/, ''); // Trim - from the end of a text
    },

    getTelLink(telString = '') {
        return `tel:+${(telString || '').replaceAll(/\D/g, '')}`;
    },

    toSHA256String(inputString: string) {
        const hash = CryptoJS.SHA256(inputString);
        return hash.toString(CryptoJS.enc.Hex);
    },

    sanitize(str: string) {
        return str.replace(/<[^>]*>?/gm, '');
    },

    rangeRec(low, hi, values) {
        if (low > hi) return values;
        values.push(low);
        return this.rangeRec(low + 1, hi, values);
    },

    range(low, hi) {
        return this.rangeRec(low, hi, []);
    },
};
